import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import RootMinimalLayout from "./RootMinimalLayout";
import ThankyouPage from "views/pages/thank-you/ThankyouPage";
import HomePage from "views/new-website/pages/HomePage";
import Services from "views/new-website/pages/Services";
import Knowledge from "views/new-website/pages/Knowledge";
import About from "views/new-website/pages/About";
import Testimonials from "views/new-website/pages/Testimonials";
import GettingStarted from "views/new-website/pages/GettingStarted";
import LegalNotice from "views/new-website/pages/LegalNotice";
import DataPrivacy from "views/new-website/pages/DataPrivacy";
import DataPrivacyDE from "views/new-website/pages/DataPrivacyDE";
import Learn from "views/new-website/pages/Learn";
import Kompensation from "views/new-website/pages/Kompensation";
import CO2Zertifikate from "views/new-website/pages/CO2Zertifikate";
import Klimaschutzprojekt from "views/new-website/pages/Klimaschutzprojekt";
import CarbonCredits from "views/new-website/pages/Carbon-credits";
import OurProjects from "views/new-website/pages/OurProjects";

// maintenance routing
const MaintenanceError = Loadable(
  lazy(() => import("views/pages/maintenance/Error"))
);
const MaintenanceComingSoon1 = Loadable(
  lazy(() => import("views/pages/maintenance/ComingSoon/ComingSoon1"))
);
const MaintenanceComingSoon2 = Loadable(
  lazy(() => import("views/pages/maintenance/ComingSoon/ComingSoon2"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("views/pages/maintenance/UnderConstruction"))
);

// landing & contact-us routing
const PagesContactUS = Loadable(lazy(() => import("views/pages/contact-us")));
const PagesFaqs = Loadable(lazy(() => import("views/pages/saas-pages/Faqs")));
const PagesPrivacyPolicyDE = Loadable(
  lazy(() => import("views/pages/saas-pages/PrivacyPolicyDE"))
);
const PagesPrivacyPolicyEN = Loadable(
  lazy(() => import("views/pages/saas-pages/PrivacyPolicyEN"))
);

const PagesImprintEN = Loadable(
  lazy(() => import("views/pages/saas-pages/ImprintEN"))
);

const PagesAbout = Loadable(lazy(() => import("views/pages/about")));
const PagesCreditBuyers = Loadable(
  lazy(() => import("views/pages/credit-buyers"))
);
const PagesCreditSellers = Loadable(
  lazy(() => import("views/pages/credit-sellers"))
);

const PagesLearn = Loadable(lazy(() => import("views/pages/learn")));
const CertificationStandards = Loadable(
  lazy(() => import("views/pages/learn/content/CertificationStandards"))
);
const HighQualityProjects = Loadable(
  lazy(() => import("views/pages/learn/content/HighQualityProjects"))
);
const PricingCo2Credits = Loadable(
  lazy(() => import("views/pages/learn/content/PricingCo2Credits"))
);
const SocialBenefits = Loadable(
  lazy(() => import("views/pages/learn/content/SocialBenefits"))
);
const UnderstandingProjectTypes = Loadable(
  lazy(() => import("views/pages/learn/content/UnderstandingProjectTypes"))
);
const ImportanceCarbonOffsetting = Loadable(
  lazy(() => import("views/pages/learn/content/ImportanceCarbonOffsetting"))
);

const ViewsContactShort = Loadable(
  lazy(() => import("views/hub-spot/contact-short"))
);
const ViewsContactLong = Loadable(
  lazy(() => import("views/hub-spot/contact-long"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <RootMinimalLayout />,
  children: [
    {
      path: "/",
      element: <HomePage />,
    },

    {
      path: "/pages/landing",
      element: <HomePage />,
    },
    // {
    //   path: "/pages/contact-us",
    //   element: <PagesContactUS />,
    // },
    // {
    //   path: "/pages/contact/shortform",
    //   element: <ViewsContactShort />,
    // },
    // {
    //   path: "/getting-started",
    //   element: <ViewsContactLong />,
    // },
    // {
    //   path: "/getting-started-20230428",
    //   element: <ViewsContactLong />,
    // },
    {
      path: "/thank-you",
      element: <ThankyouPage />,
    },
    /*{
            path: '/pages/faqs',
            element: <PagesFaqs />
        },*/
    // {
    //   path: "/pages/credit-buyers",
    //   element: <PagesCreditBuyers />,
    // },
    // {
    //   path: "/pages/credit-sellers",
    //   element: <PagesCreditSellers />,
    // },
    // {
    //   path: "/pages/contact-us",
    //   element: <PagesContactUS />,
    // },

    // {
    //   path: "/pages/learn",
    //   element: <PagesLearn />,
    // },
    {
      path: "/pages/learn/importance-carbon-offsetting",
      element: <ImportanceCarbonOffsetting />,
    },
    {
      path: "/pages/learn/understanding-project-types",
      element: <UnderstandingProjectTypes />,
    },
    {
      path: "/pages/learn/high-quality-projects",
      element: <HighQualityProjects />,
    },
    {
      path: "/pages/learn/pricing-co2-credits",
      element: <PricingCo2Credits />,
    },
    {
      path: "/pages/learn/certification-standards",
      element: <CertificationStandards />,
    },
    {
      path: "/pages/learn/social-benefits",
      element: <SocialBenefits />,
    },

    // {
    //   path: "/pages/about",
    //   element: <PagesAbout />,
    // },
    // {
    //   path: "/pages/jobs",
    //   element: <PagesAbout />,
    // },
    // {
    //   path: "/pages/imprint_en",
    //   element: <PagesImprintEN />,
    // },
    // {
    //   path: "/pages/privacy_en",
    //   element: <PagesPrivacyPolicyEN />,
    // },
    // {
    //   path: "/pages/privacy_de",
    //   element: <PagesPrivacyPolicyDE />,
    // },

    // {
    //   path: "/home",
    //   element: <HomePage />,
    // },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/knowledge",
      element: <Knowledge />,
    },

    {
      path: "/about",
      element: <About />,
    },

    {
      path: "/testimonials",
      element: <Testimonials />,
    },
    {
      path: "/our-projects",
      element: <OurProjects />,
    },
    {
      path: "/getting-started",
      element: <GettingStarted />,
    },
    {
      path: "/legal-notice",
      element: <LegalNotice />,
    },

    {
      path: "/data-privacy-en",
      element: <DataPrivacy />,
    },

    {
      path: "/data-privacy-de",
      element: <DataPrivacyDE />,
    },

    {
      path: "/learn",
      element: <Learn />,
    },

    // Kompensation

    {
      path: "/kompensation",
      element: <Kompensation />,
    },

    // CO2 Zertifikate

    {
      path: "/co2-zertifikate",
      element: <CO2Zertifikate />,
    },

    // Klimaschutzprojekt

    {
      path: "/klimaschutzprojekt",
      element: <Klimaschutzprojekt />,
    },

    // Carbon Credits

    {
      path: "/carbon-credits",
      element: <CarbonCredits />,
    },

    // {
    //   path: "*",
    //   element: <MaintenanceError />,
    // },
  ],
};

export default AuthenticationRoutes;
